<template>
  <div class="row">
    <div class="left-content-auth col-md-6 col-lg-7 col-sm-6" style="height: 100vh;background: #f9f3e5e6;">
      <div class="row">
        <div class="col-sm-12">
          <p>
            <img src="assets/images/logo-mini.png" width="100" />
          </p>
          <h3 class="h3-login" style="margin-top: 25px; margin-bottom: 10px;">Você no Controle<br> de suas finanças!
          </h3>
          <p class="p-login">O Stimper é um app que vai te ajudar na gestão financeira de suas finanças pessoais de
            forma simples e intuitiva.</p>
        </div>
      </div>
      <div class="col-sm-12 img-report-auth">
        <ZenMan />
      </div>
    </div>
    <div class="col-md-6 col-lg-5 col-sm-6">
      <div class="row">
        <div class="col-lg-12">
          <div class="container-form">
            <div style="text-align: center;">
              <h3 style="margin-top: 80px; margin-bottom: 15px;">Comece a usar grátis</h3>
            </div>
            <div>
              <form class="col-md-12 p-0 mx-auto card card-login"
                style="margin-top: 10px; padding-bottom: 20px;padding-top: 65px;">
                <div class="form-group">
                  <input id="email" type="text" class="form-control" placeholder="Nome completo" v-model="form.fname" />
                </div>
                <div class="form-group">
                  <input id="password" type="text" class="form-control" placeholder="Email" v-model="form.email" />
                </div>
                <div class="form-group">
                  <input id="password" type="text" class="form-control" placeholder="Telefone" v-model="form.phone"
                    v-mask="'(##) #####-####'" />
                </div>
                <div class="form-group">
                  <input id="password" type="password" class="form-control" placeholder="Senha"
                    v-model="form.password" />
                </div>
                <div class="text-center">
                  <button type="button" class="btn btn-success btn-green-shadow radius-login" style="width: 100%;"
                    @click="register" :class="{ 'qt-loader qt-loader-mini qt-loader-right': isSending }"
                    :disabled="isSending">Cadastrar</button>
                </div>
                <div class="text-left p-signup">Já possui conta? <router-link :to="'/log'">Entrar</router-link></div>
                <div class="form-group">
                  <hr>
                  <a href="https://stimper.com.br/terms/" style="font-size: 15px;" target="_blank">Termos de uso</a> -
                  <a href="https://stimper.com.br/privacy-policy/" style="font-size: 15px;" target="_blank">Política de
                    Privacidade</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AuthService from '@/services/auth.service';
import pace from 'pace-js';
import ZenMan from '@/components/anim/ZenMan.vue';

export default {
  name: 'Login',
  components: {
    ZenMan,
  },
  data() {
    return {
      isSending: false,
      form: {
        password: '',
        email: '',
        fname: '',
        phone: '',
        plataform: 'web',
      },
    };
  },
  mounted() {
  },
  methods: {
    register() {
      this.isSending = true;
      pace.start();
      AuthService.register(this.form).then(
        (response) => {
          this.isSending = false;
          this.$toast.show({
            title: 'Sucesso',
            content: 'Conta criada com sucesso!',
            type: 'success',
          });
          console.log(response);
          this.$router.push('/');
        },
        (error) => {
          this.isSending = false;
          console.log(error.response.data);
          this.$toast.show({
            title: 'Erro',
            content: 'Erro interno, por favor tente novamente mais tarde',
            type: 'error',
          });
          this.content = error;
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
#auth {
  background: #fff;
  width: 100%;

  .row {
    height: 100vh;

    &>.d-none {
      background-size: cover;
    }

    &> :last-child {
      padding: 40px;
    }
  }

  .logo {
    width: 168px;
    height: 30px;
    float: right;
    margin-bottom: 1em;
  }

  .auth-form {
    min-height: 200px;
    max-width: 300px;
    padding: 0 4px;
    margin: 0 auto;
    position: relative;
    top: 20%;
    transform: translateY(-20%);

    h1 {
      padding-bottom: 1.5em;
      font-size: 1.5em;
      font-weight: bold;
    }

    .form-label {
      font-weight: bold;
    }
  }
}

.card-login {
  float: none;
  margin: 0 auto;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 70px;
  padding-top: 40px;
}

.container {
  padding-top: 0px;
}

.p-logo {
  text-align: center;
  padding: 20px;
}

.p-logo img {
  width: 140px;
  -webkit-filter: grayscale(1);
}

.p-signup {
  margin-top: 30px;
}

.p-signup a {
  color: #18b272;
  cursor: pointer;
}

.p-signup a:hover {
  color: #249428;
  text-decoration: underline;
}

.h3-login {
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  line-height: 60px;
}

.radius-login {
  border-radius: 15px;
}

.img-report-auth {
  padding-left: 170px;
  padding-top: 60px;
  height: 80%;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: contain;
}

.left-content-auth {
  padding-left: 170px;
  padding-top: 60px;
}

.img-report-auth img {
  width: 40%;
}

.container-form {
  padding-right: 60px !important;
  padding-left: 20px !important;
}

@media only screen and (max-width: 1166px) {
  .left-content-auth {
    padding-left: 100px;
    padding-top: 60px;
  }

  .container-form {
    padding-left: 0px !important;
  }
}

@media only screen and (max-width: 975px) {
  .left-content-auth {
    padding-left: 50px;
    padding-top: 60px;
  }

  form {
    margin-right: 15px !important;
  }

  .img-report-auth {
    width: 110% !important;
    padding-left: 70px;
  }
}

@media only screen and (max-width: 924px) {
  .container-form {
    padding-right: 10px !important;
  }

  .h3-login {
    font-size: 40px;
    line-height: 40px;
  }

  .left-content-auth p {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .container-form {
    padding-right: 20% !important;
    padding-left: 20% !important;
  }

  form {
    margin-right: 0px !important;
  }

  .left-content-auth {
    padding-top: 0px;
    text-align: center;
  }

  .h3-login {
    display: none;
  }

  .left-content-auth .p-login {
    display: none;
  }

  .left-content-auth p {
    margin-top: 15px;
  }

  .img-report-auth {
    display: none;
  }

  .left-content-auth {
    padding-left: 0px !important;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 641px) {
  .container-form {
    padding-right: 15% !important;
    padding-left: 15% !important;
  }
}

@media only screen and (max-width: 533px) {
  .container-form {
    padding-right: 8% !important;
    padding-left: 8% !important;
  }
}
</style>
